import React from "react"
import { Form } from "react-bootstrap"

export const Specials =
    ({
        badDay, highEnergyUsage, unknownSkus, onBadDay, onHighEnergyUsage, onUnknownSkus
    }:
        { badDay: boolean, highEnergyUsage: boolean, unknownSkus: boolean, onBadDay: () => Promise<void>, onHighEnergyUsage: () => Promise<void>, onUnknownSkus: () => Promise<void> }) => {

        const [loading, setLoading] = React.useState(false)

        const wrapInLoading = async (f: () => Promise<void>) => {
            setLoading(true)
            await f()
            setLoading(false)
        }

        return (
            <ul className="w-100 list-group">
                <li className="list-group-item flex-column align-items-start">
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-0">Other</h5>
                        <Form.Check
                            type="switch"
                            id="unknown-sku-switch"
                            style={{ transform: "scale(1.5, 1.5)" }}
                            onChange={(e) => wrapInLoading(onUnknownSkus)}
                            disabled={loading}
                            checked={unknownSkus}
                        />
                    </div>
                </li>
                <li className="list-group-item flex-column align-items-start">
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-0">High energy usage</h5>
                        <Form.Check
                            type="switch"
                            id="high-energy-usage-switch"
                            style={{ transform: "scale(1.5, 1.5)" }}
                            onChange={(e) => wrapInLoading(onHighEnergyUsage)}
                            disabled={loading}
                            checked={highEnergyUsage}
                        />
                    </div>
                </li>
                <li className="list-group-item flex-column align-items-start">
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-0">Bad day</h5>
                        <Form.Check
                            type="switch"
                            id="bad-day-switch"
                            onChange={(e) => wrapInLoading(onBadDay)}
                            style={{ transform: "scale(1.5, 1.5)" }}
                            disabled={loading}
                            checked={badDay}
                        />
                    </div>
                </li>
            </ul>
        )
    }

export default Specials