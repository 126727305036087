import axios, { Axios, AxiosError, AxiosRequestConfig } from "axios";
import { UserManager } from "oidc-react";
import { createContext, useContext } from "react";

export const createAxiosContext = (userManager: UserManager) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL

    const MAX_RETRIES = 3

    const refresh = async () => {
        try {
            await userManager.signinSilent()
        } catch (error) {
            console.error("Error during token refresh", error)
            console.log("Error A", (error as any).name)

            if ((error as any).error === "invalid_grant" || (error as any).name === "ErrorTimeout") {
                console.log("Invalid grant, redirecting to sign in...")
                await userManager.signinRedirect()
            } else {
                console.error("Error getting access token", error)
                throw error
            }
        }
    }

    const configuredAxios = axios.create({ baseURL: backendUrl })

    configuredAxios.interceptors.request.use(async config => {
        const userData = await userManager.getUser()

        let token

        if (userData && userData.expires_in && userData.expires_in < 60) {
            await refresh()
            token = (await userManager.getUser())?.access_token
        } else {
            token = userData?.access_token
        }

        if (token && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config
    })


    type ExtendedConfig = AxiosRequestConfig & { retryCount?: number }

    configuredAxios.interceptors.response.use((value) => value, async (error: AxiosError) => {
        const config: ExtendedConfig = error.config

        // Check if we've run out of retries
        if (!config || (error.response?.status !== 401) || (config.retryCount && config.retryCount >= MAX_RETRIES)) {
            return Promise.reject(error)
        }

        config.retryCount = config.retryCount || 0
        config.retryCount += 1

        await refresh()
        
        // Retry the request
        return configuredAxios(config)
    })

    return configuredAxios
}


export const AxiosContext = createContext<Axios>(axios)

export const useAxios = () => {
    const axiosInstance = useContext(AxiosContext)
    if (!axiosInstance) {
        throw new Error('useAxios must be used within an AxiosProvider')
    }
    return axiosInstance
}