import { Moment, Duration } from 'moment';
import * as moment from 'moment';

export type TaskId = string

export interface TaskWithId {
    id: TaskId;
    task: Task;
}

export interface Record {
    timestamp: Moment;
    user: String;
}

export class Task {
    name: String;
    group?: String;
    records: Record[];
    reservation?: Record;
    period: Duration;
    timeRemainingPercent: number;

    constructor(name: String, records: Record[], period: Duration, group?: String, reservation?: Record) {
        this.group = group
        this.name = name
        this.records = records
        this.period = period
        this.timeRemainingPercent = this.calculateTimeRemainingPercent()
        this.reservation = reservation
    }

    mostRecentRecord(): Record | undefined {
        if (this.records.length) {
            return this.records[this.records.length - 1]
        } else {
            return undefined
        }
    }

    calculateTimeRemainingPercent(): number {
        const mostRecentRecord = this.mostRecentRecord()

        if (mostRecentRecord) {
            const elapsedDuration = moment.duration(mostRecentRecord.timestamp.diff(moment.default())).abs()
            return Math.round((1 - elapsedDuration.asSeconds() / this.period.asSeconds()) * 100)
        } else {
            return 0
        }
    }

    timeAgo(): String {
        const record = this.mostRecentRecord()
        if (record === undefined) {
            return "never"
        } else {
            return record.timestamp.fromNow()
        }
    }

    isToday(): boolean {
        const record = this.mostRecentRecord()
        if (record === undefined) {
            return false
        } else {
            return record.timestamp.isSame(moment.default(), "day")
        }
    }

    withNewRecord(record: Moment, user?: String): Task {
        return new Task(this.name, [...this.records, { timestamp: record, user: user || "me" }], this.period, this.group)
    }

    withoutRecord(timestamp: Moment): Task {
        return new Task(this.name, this.records.filter(r => r.timestamp !== timestamp), this.period, this.group)
    }

    withReservation(record: Record): Task {
        return new Task(this.name, this.records, this.period, this.group, record)
    }

    withoutReservation(): Task {
        return new Task(this.name, this.records, this.period, this.group, undefined)
    }
}