import React from 'react';
import './index.css';
import App from './App';
import {createRoot} from 'react-dom/client'
import reportWebVitals from './reportWebVitals';
import { AsyncAuthProvider } from './AsyncAuthProvider';
import * as Oidc from "oidc-client-ts"


const root = createRoot(
  document.getElementById('root') as HTMLElement
);

Oidc.Log.setLogger(console)
Oidc.Log.setLevel(Oidc.Log.DEBUG)

root.render(
  <React.StrictMode>
    <AsyncAuthProvider>
      <App />
    </AsyncAuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
