import React from "react";

import { Form, Button, FloatingLabel, Row, Col } from 'react-bootstrap'
import { Task } from "./Task";
import moment from "moment"

export default function TaskEditor({ onSave, onCancel, onDelete, task }: { onSave: (task: Task) => Promise<void>, onCancel: () => void, onDelete: (() => Promise<void>) | null, task: Task | null }) {

    const [name, setName] = React.useState(task ? task.name.toString() : "")

    const [group, setGroup] = React.useState((task && task.group) ? task.group.toString() : "")

    const [period, setPeriod] = React.useState(task?.period ? task.period.days() : 7)
    const [loading, setLoading] = React.useState(false)

    const emitOnSave = async () => {
        setLoading(true)
        await onSave(new Task(name, task?.records ? task.records : [], moment.duration(period, "days"), group.trim() ? group.trim() : undefined))
        setLoading(false)
    }

    const emitOnDelete = async () => {
        if (onDelete) {
            setLoading(true)
            await onDelete()
            setLoading(false)
        }
    }

    return <div className="w-100 d-flex align-items-center">
        <Form className="flex-grow-1">
            <Row>
                <Col>
                    <FloatingLabel controlId="task-edit-group" label="Group (optional)">
                        <Form.Control autoFocus={true} type="text" placeholder="Group" defaultValue={group} onChange={e => setGroup(e.target.value)} disabled={loading} />
                    </FloatingLabel>

                </Col>
                <Col>
                    <FloatingLabel controlId="task-edit-name" label="Name">
                        <Form.Control type="text" placeholder="Name" defaultValue={name} onChange={e => setName(e.target.value)} disabled={loading} />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel controlId="task-edit-period" label="Period">
                        <Form.Control type="number" placeholder="Period" defaultValue={period} onChange={e => setPeriod(+e.target.value)} disabled={loading} />
                    </FloatingLabel>
                </Col>
            </Row>
        </Form>
        <div className="ms-3">
            {
                loading ?
                    <button className="btn btn-success" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        &nbsp;{task ? "Saving..." : "Adding..."}
                    </button>
                    :
                    <>
                        <Button variant="secondary" onClick={() => onCancel()} disabled={loading}>Cancel</Button>
                        &nbsp;
                        {onDelete && <Button variant="danger" onClick={() => window.confirm("Are you sure?") && emitOnDelete()} disabled={loading}>Delete</Button>}
                        &nbsp;
                        <Button type="submit" variant="success" onClick={e => emitOnSave()} disabled={loading}>{task ? "Save" : "Add"}</Button>
                    </>
            }

        </div>

    </div>

}