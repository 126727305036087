import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, ProgressBar, Row, Spinner } from "react-bootstrap";
import { useAxios } from '../AxiosContext';
import Ean from './Ean';
import MonthlyChart from "./MonthlyChart";
import NewSku from "./NewSku";
import Specials from "./Specials";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faSpinner } from '@fortawesome/free-solid-svg-icons';

const DailySummary = () => {
    const [entries, setEntries] = useState<any[]>([])
    const [filter, setFilter] = useState("")
    const [specialEntries, setSpecialEntries] = useState<{ badDay: boolean, highEnergyUsage: boolean, unknownSkus: boolean }>({ badDay: false, highEnergyUsage: false, unknownSkus: false })
    const [dailyLimit, setDailyLimit] = useState(0.0001)
    const [currentTotalPercentage, setCurrentTotalPercentage] = useState(0)
    const [loading, setLoading] = useState(true)
    const [lastUpdate, setLastUpdate] = useState<Moment | null>(null)
    const [date, setDate] = useState<Moment>(moment().startOf("day"))
    const [refreshCounter, setRefreshCounter] = useState(0)
    const axios = useAxios()

    const [creatingSku, setCreatingSku] = useState(false)

    function isToday(date: Moment) {
        const now = moment()
        return date.isSame(now, "day")
    }

    function toggleDate() {
        if (isToday(date)) {
            console.log("Changing to:", moment().subtract(1, "day").toISOString())
            setDate(moment().subtract(1, "day").startOf("day"))
        } else {
            setDate(moment().startOf("day"))
        }
    }

    // TODO type
    function entryFilter(entry: any) {
        return entry.sku.name.toLowerCase().includes(filter.toLowerCase())
    }

    // TODO type
    function catchPlusMinus(e: any) {
        const filteredEntries = entries.filter(entry => entryFilter(entry))
        if ((e.key === "+" || e.key === "-" || e.key === "=")) {
            e.preventDefault()
            if (filteredEntries.length > 0) {
                if (e.key === "-" && filteredEntries[0].portions > 0) {
                    recordSku(filteredEntries[0].sku.ean, -1)
                } else if (e.key === "+") {
                    recordSku(filteredEntries[0].sku.ean, 1)
                }
            }
        }

    }

    function recordSku(ean: Ean, portionCount: number) {
        return axios.post('/v1/sugar/records', {
            "@type": "SpecificSku",
            ean,
            portionCount,
            instant: getRecordTimestamp()
        }).then(response => {
            setEntries(entries => entries.map(entry => {
                if (entry.sku.ean === ean) {
                    return { ...entry, portions: entry.portions + portionCount }
                } else {
                    return entry
                }
            }))
        })
    }

    async function toggleUknownSkus(): Promise<void> {
        const newValue = !specialEntries.unknownSkus
        await sendSpecialEvent("UnknownSkus", newValue)
        setSpecialEntries({ ...specialEntries, unknownSkus: newValue })
    }

    async function toggleHighEnergyUsage(): Promise<void> {
        const newValue = !specialEntries.highEnergyUsage
        await sendSpecialEvent("HighEnergyUsage", newValue)

        setSpecialEntries({ ...specialEntries, highEnergyUsage: newValue })
    }

    async function toggleBadDay(): Promise<void> {
        const newValue = !specialEntries.badDay
        await sendSpecialEvent("BadDay", newValue)

        setSpecialEntries({ ...specialEntries, badDay: newValue })
    }

    async function sendSpecialEvent(eventName: string, toggled: boolean) {
        axios.post('/v1/sugar/records', {
            "@type": eventName,
            toggled,
            instant: getRecordTimestamp()
        })
    }

    function getRecordTimestamp() {
        if (isToday(date)) {
            return moment().toISOString()
        } else {
            const today = moment()
            const beginningOfToday = moment().startOf("day")
            const endOfSelectedDay = date.clone().endOf("day")
            const diffMs = today.diff(beginningOfToday, 'milliseconds');

            return endOfSelectedDay.toISOString().substring(0, 19) + ".9" + diffMs.toString().padStart(8, "0") + "Z"
        }
    }

    const triggerRefresh = useCallback(() => {
        setRefreshCounter(refreshCounter + 1)
    }, [refreshCounter])

    // TODO sku any
    async function createSku(sku: any) {
        console.log("Creating SKU...")
        let response = await axios.put('/v1/sugar/skus', sku)
        console.log(response)
        setCreatingSku(false)
        triggerRefresh()
        return response;
    }

    function calculateTotalPercentage(entries: any[], dailyLimit: number) {
        return entries.map(entry => entry.sku.sugar / 100 * entry.sku.portion.weight * entry.portions).reduce((a, b) => a + b, 0) / dailyLimit * 100
    }


    useEffect(() => {
        setCurrentTotalPercentage(calculateTotalPercentage(entries, dailyLimit))
    }, [entries, dailyLimit])


    useEffect(() => {
        const onFocus = () => {
            if (lastUpdate) {
                const threshold = lastUpdate
                const now = moment()

                const diff = moment.duration(now.diff(threshold))
                if (diff.asMinutes() > 1 && !creatingSku) {
                    triggerRefresh()
                }
            }
        }

        window.addEventListener("focus", onFocus)
        return () => {
            window.removeEventListener("focus", onFocus)
        }
    }, [lastUpdate, triggerRefresh, creatingSku])

    useEffect(() => {
        const fetchDailySummary = () => {
            console.log(`Fetching with date ${date.format('YYYY-MM-DD')}...`)

            setLoading(true)

            axios.get('/v1/sugar/daily-summary', { params: { date: date.format('YYYY-MM-DD') } }).then(response => {
                setEntries(entries => {
                    // TODO types
                    return response.data.entries.sort((a: any, b: any) => {
                        return entries.findIndex(entry => entry.sku.ean === a.sku.ean) - entries.findIndex(entry => entry.sku.ean === b.sku.ean)
                    })
                })
                setSpecialEntries({
                    badDay: response.data.badDay,
                    highEnergyUsage: response.data.highEnergyUsage,
                    unknownSkus: response.data.unknownSkus
                })
                setDailyLimit(response.data.limit)
                setLoading(false)
                setLastUpdate(moment())
            })
        }

        fetchDailySummary()
    }, [date, refreshCounter])

    return <div className="container">
        <div className="py-2 sticky-top" style={{ background: "#1e2124" }}>
            <ProgressBar striped variant={(currentTotalPercentage > 150) ? 'danger' : (currentTotalPercentage > 100) ? 'warning' : 'success'} now={currentTotalPercentage} label={`${Math.round(currentTotalPercentage)}%`} />
            <div className="d-flex mt-2">
                <Form.Control type="text" placeholder="Filter..." size="lg" autoFocus={true} onKeyDown={(e: any) => catchPlusMinus(e)} onChange={(e: any) => setFilter(e.target.value)} />
                <Button className="ms-1" variant="success" onClick={() => setCreatingSku(true)} disabled={creatingSku}>+</Button>
            </div>
        </div>

        {(loading && (entries.length === 0)) ?
            <div className="text-center my-3">
                <Spinner animation="border" />
            </div>
            :
            (
                creatingSku ?
                    <Container>
                        <Row className="justify-content-md-center mt-2">
                            <Card className="col-sm-4">
                                <Card.Body>
                                    {
                                        <NewSku onSubmit={(sku: any) => createSku(sku)} onCancel={() => setCreatingSku(false)} />
                                    }
                                </Card.Body>
                            </Card>
                        </Row>
                    </Container>
                    :
                    < Container style={{ filter: isToday(date) ? "grayscale(0)" : "grayscale(0.7)" }}>
                        <Row xs="12">
                            <MonthlyChart dailyLimit={dailyLimit} dateFrom={date.clone().subtract(30, "days").startOf("day")} dateTo={date.clone().subtract(1, "day").startOf("day")} />
                        </Row>
                        <Row xs="12">
                            <Specials
                                badDay={specialEntries.badDay}
                                highEnergyUsage={specialEntries.highEnergyUsage}
                                unknownSkus={specialEntries.unknownSkus}
                                onBadDay={() => toggleBadDay()}
                                onHighEnergyUsage={() => toggleHighEnergyUsage()}
                                onUnknownSkus={() => toggleUknownSkus()}
                            />
                        </Row>
                        <Row xs="12">
                            <Col className="text-center my-2">
                                {isToday(date) ?
                                    <>Showing data for today.&nbsp;<a href="#" onClick={(e) => { e.preventDefault(); toggleDate() }}>Switch to yesterday</a>?</> :
                                    <>Showing data for yesterday.&nbsp;<a href="#" onClick={(e) => { e.preventDefault(); toggleDate() }}>Switch to today</a>?</>
                                }
                            </Col>

                        </Row>
                        <Row xs="12">

                            <ul className="w-100 list-group my-2">
                                {entries.filter(entry => entryFilter(entry)).map(entry => {
                                    return <li key={entry.sku.ean} className="list-group-item flex-column align-items-start">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{entry.sku.name}<br /><small>{entry.sku.portion.weight} g ({entry.sku.portion.description})</small></h5>
                                            <small className='d-flex' style={{ 'minWidth': '10em' }}><div className="input-group mb-3 justify-content-end">
                                                <div className="input-group-prepend">
                                                    <button type="button" className="btn btn-danger" onClick={() => recordSku(entry.sku.ean, -1)} disabled={entry.portions === 0 || loading}>-</button>
                                                </div>
                                                <span className="input-group-text" style={{}}>{entry.portions}</span>
                                                <div className="input-group-append">
                                                    <button type="button" disabled={loading} className="btn btn-success" onClick={() => recordSku(entry.sku.ean, 1)}>+</button>
                                                </div>
                                            </div></small>
                                        </div>
                                        {(entry.portions > 0) ?
                                            <ProgressBar variant="primary" striped now={entry.sku.sugar * entry.sku.portion.weight / dailyLimit * entry.portions} style={{ height: '0.5em' }} />
                                            :
                                            <ProgressBar variant="secondary" striped now={entry.sku.sugar * entry.sku.portion.weight / dailyLimit} style={{ height: '0.5em', 'opacity': '0.5' }} />
                                        }
                                    </li>
                                })}
                            </ul>
                        </Row>
                    </Container>
            )
        }
        <div className="text-center my-2">
            {loading ? <Button variant="secondary" disabled><FontAwesomeIcon icon={faSpinner} spin={true} /> Refreshing...</Button> :
                <Button variant="secondary" onClick={() => triggerRefresh()}><FontAwesomeIcon icon={faRefresh} /> Refresh</Button>
            }
        </div>
    </div >
}

export default DailySummary
