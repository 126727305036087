import { faCalendarCheck, faCalendarXmark, faCheck, faEdit, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap'

export class TaskButtons extends React.Component<{
    isToday: boolean,
    reserved: boolean,
    onDone: () => Promise<void>,
    onUndo: () => Promise<void>,
    onEdit: () => void,
    onReservation: () => Promise<void>,
    onReservationCancel: () => Promise<void>,
    onReservationConfirmation: () => Promise<void>,
    disableReservationConfirmation: boolean
}, { loading: boolean }> {
    constructor(props: any) {
        super(props)
        this.state = { loading: false }
    }

    private async onDone() {
        this.setState({ loading: true })
        await this.props.onDone()
        this.setState({ loading: false })
    }

    private async onReservation() {
        this.setState({ loading: true })
        await this.props.onReservation()
        this.setState({ loading: false })
    }

    private async onReservationCancel() {
        this.setState({ loading: true })
        await this.props.onReservationCancel()
        this.setState({ loading: false })
    }

    private async onReservationConfirmation() {
        if (this.props.onReservationConfirmation) {
            this.setState({ loading: true })
            await this.props.onReservationConfirmation()
            this.setState({ loading: false })
        }
    }

    private async onUndo() {
        this.setState({ loading: true })
        await this.props.onUndo()
        this.setState({ loading: false })
    }

    render() {
        const editButton = <Button variant="link" className="text-body" onClick={() => this.props.onEdit()}><FontAwesomeIcon icon={faEdit} /></Button>

        if (this.state.loading) {
            return <><button className="btn btn-outline-secondary" type="button" disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button></>
        } else if (this.props.isToday) {
            return <>{editButton}&nbsp;<Button variant="outline-secondary" onClick={() => this.onUndo()}><FontAwesomeIcon icon={faRotateLeft} /></Button></>
        } else if (this.props.reserved) {
            return <>
            {editButton}
            <Button variant="danger" onClick={() => this.onReservationCancel()}><FontAwesomeIcon icon={faCalendarXmark} /></Button>
            {" "}
            <Button variant="success" disabled={this.props.disableReservationConfirmation} onClick={() => this.onReservationConfirmation()}><FontAwesomeIcon icon={faCalendarCheck} /></Button>
            </>
        } else {
            return <>
            {editButton}
            <Button variant="primary" onClick={() => this.onReservation()}><FontAwesomeIcon icon={faCalendarCheck} /></Button>
            {" "}
            <Button variant="success" onClick={() => this.onDone()}><FontAwesomeIcon icon={faCheck} /></Button>
            </>
        }

    }
}