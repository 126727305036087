import moment, { Moment } from "moment"
import * as momentModule from "moment"
import { useContext, useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts"
import { extendMoment } from "moment-range"
import EventBus from "../EventBus"
import { AxiosContext } from "../AxiosContext"

const MonthlyChart = () => {
    const [loading, setLoading] = useState(true)

    const [data, setData] = useState<any>(undefined)

    const momentWithRange = extendMoment(momentModule)

    const max = 5

    const colours = ["#0c6", "#07c"]

    const axios = useContext(AxiosContext)

    useEffect(() => {
        const processRawData = (start: Moment, end: Moment, data: any) => {
            const users = Object.entries(data).map(([key,]) => {
                return key
            })

            const dates = Array.from(momentWithRange.range(start, end).by("day"))

            return {
                users: users,
                data: dates.map(m => {
                    const localDate = m.format("YYYY-MM-DD")
                    const entry = { name: localDate } as any
                    let total = 0
                    for (const user of users) {
                        entry[user] = data[user][localDate] || 0
                        total += entry[user] // TODO ugh
                    }
                    if ([5, 6].includes(m.day())) {
                        entry["weekend"] = Math.max(max - total, 0)
                    } else {
                        entry["week"] = Math.max(max - total, 0)
                    }
                    return entry
                }),
                daysOfWeek: dates.map((m, index) => {
                    return {
                        index: index, 
                        isWeekend: [5, 6].includes(m.day())
                    }
                })
            }

        }

        const fetchData = async () => {
            const start = moment().subtract(30, "days")
            const end = moment().subtract(0, "days")
            const rawData = await axios.get(`/v1/cleaning/statistics`, { params: { start: start.format("YYYY-MM-DD"), end: end.format("YYYY-MM-DD") } })
            setData(processRawData(start, end, rawData.data))
            setLoading(false)
        }

        const unsubscribeTaskUpdated = EventBus.taskUpdated.on(async moment => {
            await new Promise(r => setTimeout(r, 500));
            fetchData()
        })

        fetchData()

        return () => unsubscribeTaskUpdated()

    }, [momentWithRange, axios])

    if (loading) {
        return <div className="d-flex align-items-center justify-content-center" style={{minHeight: "100px"}}><Spinner animation="border" className="m-3" /></div>
    } else {
        return <ResponsiveContainer width="100%" height={100} className="pt-2">
            <BarChart width={150} height={40} data={data.data} barGap={0}>

                {/* <Tooltip labelFormatter={((index: number) => { return data.data[index].name })} /> TODO dark background */}
                {data.users.map((user: any, index: number) => {
                    return <Bar key={user} dataKey={user} stackId="stackId" fill={colours[index % colours.length]} />
                })}

                <Bar dataKey="weekend" stackId="stackId" fill="#333" />
                <Bar dataKey="week" stackId="stackId" fill="#444" />

                <XAxis minTickGap={0} tickFormatter={(index) => data.data[index].name.substring(8)} />
                <YAxis type="number" domain={[0, max]} hide allowDataOverflow={true} />
            </BarChart>
        </ResponsiveContainer>

    }
}

export default MonthlyChart