import { faCancel, faCheck, faRandom, faSave, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext } from "react"
import { useEffect, useState } from "react"
import { Button, ButtonGroup, Col, Container, FloatingLabel, Form, Modal, Row, Spinner, ToggleButton } from "react-bootstrap"
import { useNavigate, useParams } from "react-router"
import { Habit, HabitExpectationsPeriod, HabitId } from "./Habit"
import { HabitRepositoryContext } from "./HabitRepositoryContext"

export const HabitForm = () => {
    const { habitId } = useParams()

    const [period, setPeriod] = useState(HabitExpectationsPeriod.Day)
    const [name, setName] = useState("")
    const [minimum, setMinimum] = useState(1)
    const [optimum, setOptimum] = useState(1)
    const [sensitive, setSensitive] = useState(false)
    const [loading, setLoading] = useState(!!habitId)
    const [showDeletionModal, setShowDeletionModal] = useState(false)

    const navigate = useNavigate()

    const repository = useContext(HabitRepositoryContext)

    const periodOptions = [
        { name: "a day", value: HabitExpectationsPeriod.Day },
        { name: "a week", value: HabitExpectationsPeriod.Week }
    ]

    const correctData = (name.trim().length >= 3) && (minimum > 0) && (optimum >= minimum)

    const validateAndSetMinimum = (value: string) => {
        const int = parseInt(value)

        if (int > 0) {
            setMinimum(int)
        } else {
            setMinimum(0)
        }
    }

    const validateAndSetOptimum = (value: string) => {
        const int = parseInt(value)

        if (int > 0) {
            setOptimum(int)
        } else {
            setOptimum(0)
        }
    }

    const save = async () => {
        try {
            const habit: Habit = {
                name,
                expectations: {
                    period,
                    minimum,
                    optimum
                },
                position: 99,
                sensitive
            }
            setLoading(true)
            if (habitId) {
                await repository.updateHabit(habitId, habit)
            } else {
                await repository.createHabit(habit)
            }

            navigate("../")
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    const initiateDeletion = async () => {
        setShowDeletionModal(true)
    }

    const deleteHabit = async () => {
        try {
            if (habitId) {
                await repository.deleteHabit(habitId)
            }
            navigate("../")
        } catch (error) {
            console.error(error)
        }
    }

    const closeDeletionModal = () => {
        setShowDeletionModal(false)
    }

    useEffect(() => {
        const fetch = async (habitId: HabitId) => {
            const habit = await repository.getHabit(habitId)

            if (habit) {
                setName(habit.name)
                setMinimum(habit.expectations.minimum)
                setOptimum(habit.expectations.optimum)
                setPeriod(habit.expectations.period)
                setSensitive(habit.sensitive)
                setLoading(false)
            } else {
                console.warn("Could not find habit:", habitId)
                navigate("../")
            }

        }

        if (habitId) fetch(habitId)
    }, [habitId])

    return <Container>
        <Row>
            <Col>
                <Modal show={showDeletionModal} onHide={closeDeletionModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeletionModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={deleteHabit}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {loading ?
                    <div><Spinner animation="border" /></div>
                    :
                    <Form className="my-3" onSubmit={(e) => { e.preventDefault(); save() }}>
                        <FloatingLabel
                            controlId="name"
                            label="Name"
                            className="mb-3"
                        >
                            <Form.Control type="text" placeholder="Flossing" onChange={(e) => setName(e.target.value)} defaultValue={name} />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="minimum"
                            label="Minimum"
                            className="mb-3"
                        >
                            <Form.Control type="text" placeholder="1" defaultValue={minimum} onChange={(e) => validateAndSetMinimum(e.target.value)} />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="optimum"
                            label="Desired"
                            className="mb-3"
                        >
                            <Form.Control type="text" placeholder="1" defaultValue={optimum} onChange={(e) => validateAndSetOptimum(e.target.value)} />
                        </FloatingLabel>
                        <ButtonGroup className="mb-2">
                            {periodOptions.map((periodOption, index) => (
                                <ToggleButton
                                    key={index}
                                    id={`period-option-${index}`}
                                    type="radio"
                                    variant="secondary"
                                    name="period-radio"
                                    value={periodOption.value}
                                    checked={period === periodOption.value}
                                    onChange={() => setPeriod(periodOption.value)}
                                >
                                    {periodOption.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                        {" "}
                        <ButtonGroup className="mb-2">
                            <ToggleButton
                                id="sensitive-option-regular"
                                type="radio"
                                variant="secondary"
                                name="sensitive-radio"
                                value="regular"
                                checked={!sensitive}
                                onChange={(e) => setSensitive(e.currentTarget.value === "sensitive")}
                            >
                                regular
                            </ToggleButton>
                            <ToggleButton
                                id="sensitive-option-sensitive"
                                type="radio"
                                variant="secondary"
                                name="sensitive-radio"
                                value="sensitive"
                                checked={sensitive}
                                onChange={(e) => setSensitive(e.currentTarget.value === "sensitive")}
                            >
                                sensitive
                            </ToggleButton>
                        </ButtonGroup>
                        <br />
                        {habitId &&
                            <Button variant="danger" onClick={() => initiateDeletion()}><FontAwesomeIcon icon={faTrashAlt} /> Delete</Button>
                        }
                        {' '}
                        <Button variant="secondary" onClick={() => navigate("..")}><FontAwesomeIcon icon={faCancel} /> Cancel</Button>
                        {' '}
                        {habitId ?
                            <Button type="submit" variant="primary" disabled={!correctData}><FontAwesomeIcon icon={faCheck} /> Save</Button>
                            :
                            <Button type="submit" variant="success" disabled={!correctData}><FontAwesomeIcon icon={faCheck} /> Create</Button>
                        }
                    </Form>
                }
            </Col>
        </Row>
    </Container>
}