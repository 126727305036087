import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import CleaningApp from './cleaning/CleaningApp';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import HabitsApp from './habits/HabitsApp';
import { HabitForm } from './habits/HabitForm';
import { HabitList } from './habits/HabitList';
import { Wrapper } from './Wrapper';
import SugarApp from './sugar/SugarApp';


function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Wrapper />,
      children: [
        {
          path: "",
          element: <Navigate replace to="/cleanapp" />,
        },
        {
          path: "signed-in",
          element: <Navigate replace to="/cleanapp" />,
        },
        {
          path: "signed-out",
          element: <Navigate replace to="/cleanapp" />,
        },
        {
          path: "cleanapp",
          element: <CleaningApp />
        },
        {
          path: "sugapp",
          element: <SugarApp />
        },
        {
          path: "happit",
          element: <HabitsApp />,
          children: [
            {
              path: "",
              element: <HabitList />
            },
            {
              path: "create",
              element: <HabitForm />
            },
            {
              path: ":habitId/edit",
              element: <HabitForm />
            }
          ]
        }
      ]
    }


  ])

  return (
    <div className="App">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
