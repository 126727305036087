import {
    ComposedChart,
    Bar,
    Tooltip,
    ResponsiveContainer,
    YAxis,
} from 'recharts';
import Spinner from 'react-bootstrap/Spinner'

import { Badge } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import * as Moment from "moment"
import { extendMoment } from "moment-range"
import { useAxios } from '../AxiosContext';

interface Props {
    dailyLimit: number,
    dateFrom: Moment.Moment,
    dateTo: Moment.Moment
}

const MonthlyChart: React.FunctionComponent<Props> = ({ dailyLimit, dateFrom, dateTo }) => {

    const [loading, setLoading] = useState(true)

    const [newData, setData] = useState<any[]>([])
    const axios = useAxios()

    const renderChartTooltip = (x: any) => {
        if (x.payload && x.payload.length) {
            const data = x.payload[0]
            return (
                <div style={{ background: "#303336", padding: "0.5em", borderRadius: "0.5em", border: "1px solid #ddd" }}>
                    <h6>{data.payload.date}</h6>
                    <><Badge bg="light" text="dark">{Math.round(data.value)}%</Badge><br /></>
                    {data.payload.highEnergyUsage && <><Badge bg="success">High energy usage</Badge><br /></>}
                    {data.payload.unknownSkus && <><Badge bg="warning">Other</Badge><br /></>}
                    {data.payload.error && <><Badge bg="danger">Error!</Badge><br /></>}
                    {data.payload.badDay && <><Badge bg="info">Bad day</Badge><br /></>}
                </div>
            )
        } else {
            return null
        }
    }

    useEffect(() => {
        const fetchAndProcessData = async () => {
            const fetchData = async () => {
                const response = await axios.get('/v1/sugar/long-term-summary', { params: { from: dateFrom.format('YYYY-MM-DD'), to: dateTo.format('YYYY-MM-DD') }})
                return response.data
            }
        
            const addMissingDays = (data: any) => {
                const moment = extendMoment(Moment);
        
                let mutableData = { ...data }
        
                for (let m of Array.from(moment.range(dateFrom, dateTo).by("day"))) {
                    if (!mutableData[m.format("YYYY-MM-DD")]) {
                        mutableData[m.format("YYYY-MM-DD")] = {
                            totalSugar: 0,
                            highEnergyUsage: false,
                            badDay: false,
                            unknownSkus: false,
                            error: false
                        }
                    }
                }
        
                return mutableData
            }
    
            //console.log("Fetching and processing data for long-term summary...", newData)
            const data: any[] = addMissingDays(await fetchData())
    
            const processedData = Object.entries(data).map(x => {
                const [key, value] = x
    
                const valuePercent = value.totalSugar / dailyLimit * 100
    
                const redThreshold = 150
                const yellowThreshold = 100
    
                let obj: any = {
                    date: key,
                    highEnergyUsage: value.highEnergyUsage,
                    badDay: value.badDay,
                    unknownSkus: value.unknownSkus,
                    error: value.error,
                }
    
                if (value.unknownSkus) {
                    obj.totalSugarYellow = valuePercent
                } else if (valuePercent > redThreshold) {
                    obj.totalSugarRed = valuePercent
                } else if (valuePercent > yellowThreshold) {
                    if (value.highEnergyUsage || value.badDay) {
                        obj.totalSugarAllowedYellow = valuePercent
                    } else {
                        obj.totalSugarYellow = valuePercent
                    }
                } else if (value.highEnergyUsage) {
                    obj.totalSugarBlue = valuePercent
                } else {
                    obj.totalSugarGreen = valuePercent
                }
    
                return obj
            }).sort((a, b) => {
                if (a.date < b.date) {
                    return -1;
                }
                if (a.date > b.date) {
                    return 1;
                }
    
                return 0;
            })
    
            setLoading(false)
            setData(processedData)
        }

        fetchAndProcessData()
    }, []) // TODO this might cause problems

    if (loading) {
        return <div className="w-100"><div className="text-center my-3">
            <Spinner animation="border" />
        </div></div>
    } else {
        return <ResponsiveContainer width="100%" height={100}>
            <ComposedChart width={600} height={250} data={newData}>
                <Tooltip content={renderChartTooltip} />
                <YAxis domain={[0, 150]} hide={true} />
                <Bar stackId="stack" dataKey="totalSugarYellow" fill="#ffcc00" />
                <Bar stackId="stack" dataKey="totalSugarAllowedYellow" fill="#bbcc00" />
                <Bar stackId="stack" dataKey="totalSugarRed" fill="#aa0000" />
                <Bar stackId="stack" dataKey="totalSugarGreen" fill="#00aa00" />
                <Bar stackId="stack" dataKey="totalSugarBlue" fill="#0099ee" />
            </ComposedChart>
        </ResponsiveContainer>
    }
}

export default MonthlyChart