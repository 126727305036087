import { Button, Col, Container, Row } from "react-bootstrap"
import MonthlyChart from "./MonthlyChart"
import TaskList from "./TaskList"

const CleaningApp = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <MonthlyChart />
                </Col>
            </Row>
            <Row className="my-2">
                <Col>
                    <TaskList />
                </Col>
            </Row>
        </Container>
    )
}

export default CleaningApp