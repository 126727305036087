import { faEdit, faGripLinesVertical, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Button, Card, ProgressBar, Spinner } from "react-bootstrap"
import { Habit, HabitExpectationsPeriod, HabitId, HabitRecord, HabitWithDetails } from "./Habit"
import "moment-timezone";
import moment from "moment"
import { useNavigate } from "react-router"
import { useContext } from "react"
import { HabitRepositoryContext } from "./HabitRepositoryContext"
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable"
import { CSS } from '@dnd-kit/utilities'
import "./HabitList.css"
import classNames from "classnames"
import { useAxios } from "../AxiosContext"

export const HabitList = () => {
    const axios = useAxios()

    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()

    const [habits, setHabits] = useState<HabitWithDetails[]>([])

    const [reordering, setReordering] = useState(false)

    const repository = useContext(HabitRepositoryContext)

    const record = async (id: HabitId, diff: number) => {
        const instant = moment()
        try {
            await axios.post(`/v1/habits/${id}/records`, { diff, instant: instant.toISOString() })

            setHabits(habits => habits.map(habit => {
                if (habit.id === id) {
                    return { ...habit, periodRecords: [...habit.periodRecords, { instant, diff: diff }] }
                } else {
                    return habit
                }
            }))
        } catch (error) {
            console.error(error)
        }
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
    );

    const savePosition = async (update: Map<HabitId, number>) => {
        setReordering(true)
        try {
            const response = await axios.post(`/v1/habits/reorder`, Object.fromEntries(update))
            setHabits(habits => habits.map(habitWithDetails => {
                const maybePosition = update.get(habitWithDetails.id)
                if (maybePosition) {
                    return { ...habitWithDetails, habit: { ...habitWithDetails.habit, position: maybePosition } }
                } else {
                    return habitWithDetails
                }
            }))
        } catch (error) {
            console.log(error)
        } finally {
            setReordering(false)
        }
    }

    // TODO any?
    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const activeIndex = habits.findIndex(habit => habit.id === active.id)
            const overIndex = habits.findIndex(habit => habit.id === over.id)

            const resultingArray = arrayMove(habits, activeIndex, overIndex)

            setHabits(resultingArray)

            savePosition(new Map(
                resultingArray.map((habitWithDetails, index) => [habitWithDetails.id, index + 1, habitWithDetails.habit.position] as [HabitId, number, number])
                    .filter(([, newPosition, oldPosition]) => newPosition !== oldPosition)
                    .map(([id, newPosition,]) => [id, newPosition])))
        }
    }

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true)
                const zoneId = moment.tz.guess()
                const additionalOffsetHours = 4
                const habitsWithDetails = await repository.listHabits(zoneId, additionalOffsetHours)
                setHabits(habitsWithDetails)
                setLoading(false)
            } catch (error) {
                console.error(error)
            }
        }
        load()
    }, [])

    const dayHabits = habits.filter(({ habit }) => habit.expectations.period === HabitExpectationsPeriod.Day)
    const weekHabits = habits.filter(({ habit }) => habit.expectations.period === HabitExpectationsPeriod.Week)

    return <>
        {loading ? <div className="d-flex justify-content-center my-3"><Spinner animation="border" /></div> :
            <div style={{ opacity: reordering ? "0.5" : "1.0" }}>
                <h5>Weekly</h5>
                <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
                    <SortableContext items={weekHabits} >
                        {weekHabits.map(({ id, habit, periodRecords }) => {
                            return <HabitItem id={id} habit={habit} periodRecords={periodRecords} record={(diff) => record(id, diff)} key={id} />
                        })}
                    </SortableContext>
                </DndContext>
                <h5>Daily</h5>
                <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
                    <SortableContext items={dayHabits} >
                        {dayHabits.map(({ id, habit, periodRecords }) => {
                            return <HabitItem id={id} habit={habit} periodRecords={periodRecords} record={(diff) => record(id, diff)} key={id} />
                        })}
                    </SortableContext>
                </DndContext>
            </div>

        }
        <div className="d-flex justify-content-center my-3">
            <Button variant="success" onClick={() => navigate("create")}><FontAwesomeIcon icon={faPlusCircle} /> Create new habit</Button>
        </div>

    </>
}

interface HabitItemProps {
    id: HabitId
    habit: Habit
    periodRecords: HabitRecord[],
    record: (diff: number) => void
}

export const HabitItem = ({ id, habit, periodRecords, record }: HabitItemProps) => {

    const recordSum = periodRecords.map(r => r.diff).reduce((previous, current) => previous + current, 0)

    const navigate = useNavigate()

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const [sensitiveRevealTimeout, setSensitiveRevealTimeout] = useState<NodeJS.Timeout | undefined>(undefined)

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const revealSensitive = () => {
        if (sensitiveRevealTimeout) {
            clearTimeout(sensitiveRevealTimeout)
        }
        setSensitiveRevealTimeout(setTimeout(() => setSensitiveRevealTimeout(undefined), 2000))
    }

    useEffect(() => {
        return () => {
            if (sensitiveRevealTimeout) { clearTimeout(sensitiveRevealTimeout) }
        }
    }, [sensitiveRevealTimeout])

    return <Card key={id} ref={setNodeRef} style={style} className="mb-2">
        <Card.Body className="d-flex flex-row align-items-center">
            <FontAwesomeIcon className="me-3 text-muted" style={{ cursor: "grab" }} icon={faGripLinesVertical} {...attributes} {...listeners} />
            <div className="flex-grow-1">
                <div className="d-flex justify-content-between align-items-start">
                    <div className="flex-grow-1">
                        <h6><span onClick={() => { if (habit.sensitive) { revealSensitive() } }} className={classNames({ sensitive: habit.sensitive && !sensitiveRevealTimeout, "span-thingy": true })}>{habit.name}</span> <FontAwesomeIcon icon={faEdit} onClick={() => navigate(`${id}/edit`)} className="me-1 text-muted" /></h6>
                        <div>{recordSum}/{habit.expectations.minimum} (ideally: {habit.expectations.optimum})</div>
                    </div>
                    <div>
                        <Button variant="secondary" onClick={() => record(-1)} disabled={recordSum <= 0} size="sm"><FontAwesomeIcon icon={faMinusCircle} /></Button>
                        <Button variant="success" onClick={() => record(1)} className="ms-1"><FontAwesomeIcon icon={faPlusCircle} /></Button>
                    </div>
                </div>

                <ProgressBar style={{ width: "100%" }}>
                    {
                        Array.from(Array(habit.expectations.optimum)).map((_, i) => {
                            return <ProgressBar variant={i < recordSum ? "success" : "secondary"} now={100.0 / habit.expectations.optimum} key={i} style={{ borderRadius: "1em" }} />
                        })
                    }
                </ProgressBar>
            </div>
        </Card.Body>
    </Card>

}