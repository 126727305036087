import { useCallback, useEffect, useState } from 'react';
import { Spinner, ListGroup, Alert, ProgressBar, Button, Stack } from 'react-bootstrap';
import { Record, Task, TaskId, TaskWithId } from './Task'
import * as moment from 'moment';
import { TaskButtons } from './TaskButtons';
import TaskEditor from './TaskEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPlus, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import EventBus from '../EventBus';
import classNames from 'classnames';
import { useAxios } from '../AxiosContext';

export const TaskList = () => {
    const [tasks, setTasks] = useState<TaskWithId[]>([])
    const [suggestions, setSuggestions] = useState<TaskId[]>([])
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [userMap, setUserMap] = useState<any>({})
    const [activeUser, setActiveUser] = useState<string | null>(null)
    const [inEdit, setInEdit] = useState<TaskId | string | null>(null)

    const axios = useAxios()

    async function markAsDone(taskId: TaskId): Promise<void> {
        try {
            const now = moment.default()

            await axios.put(`/v1/cleaning/tasks/${taskId}/records/${now.toISOString()}`)
            modifyTask(taskId, (task: Task) => task.withNewRecord(now))
            EventBus.taskUpdated(now)
        } catch (error) {
            console.error(error)
            setError(true)
        }
    }

    async function undo(taskWithId: TaskWithId): Promise<void> {
        const lastInstant = taskWithId.task.mostRecentRecord()?.timestamp
        if (lastInstant === undefined) {
            getList()
        } else {
            try {
                await axios.delete(`/v1/cleaning/tasks/${taskWithId.id}/records/${lastInstant.toISOString()}`)
                modifyTask(taskWithId.id, (task: Task) => task.withoutRecord(lastInstant))
                EventBus.taskUpdated(lastInstant)
            } catch (error) {
                console.error(error)
                setError(true)
            }
        }
    }

    // TODO really?
    function compareNumbers(a: number, b: number) {
        return a > b ? 1 : (a < b ? -1 : 0)
    }

    function parseRecord(record: any): Record {
        return {
            timestamp: moment.default(record.timestamp),
            user: record.user
        }
    }

    const getList = useCallback(async () => {
        setLoading(true)
        try {
            const response = await axios.get('/v1/cleaning/tasks')
            setLoading(false)
            setTasks(response.data.tasks.map((taskWithId: any) => {
                return {
                    id: taskWithId.id,
                    task: new Task(taskWithId.task.name, taskWithId.task.records.map(parseRecord), moment.duration(taskWithId.task.period), taskWithId.task.group, taskWithId.task.reservation ? parseRecord(taskWithId.task.reservation) : undefined)
                }
            }).sort((a: TaskWithId, b: TaskWithId) => {
                const byTimeRemaining = compareNumbers(a.task.timeRemainingPercent, b.task.timeRemainingPercent)

                if (!byTimeRemaining) {
                    if (a.task.group && b.task.group) {
                        const byTaskGroup = a.task.group.localeCompare(b.task.group.toString())
                        if (!byTaskGroup) {
                            return a.task.name.localeCompare(b.task.name.toString())
                        } else {
                            return byTaskGroup
                        }
                    } else if (a.task.group && !b.task.group) {
                        return 1
                    } else {
                        return -1
                    }
                } else {
                    return byTimeRemaining
                }
            }))
            setSuggestions(response.data.suggestions)
            setUserMap(response.data.userMap)
            setActiveUser(response.data.activeUser)
        } catch (error) {
            console.log(error)
            setError(true)
        }
    }, [axios])

    function modifyTask(taskId: TaskId, f: (t: Task) => Task) {
        setTasks(tasks => tasks.map(taskWithId => {
            if (taskWithId.id === taskId) {
                return {
                    id: taskId,
                    task: f(taskWithId.task)
                }
            } else {
                return taskWithId
            }
        }))
    }

    function enterEdition(taskId: TaskId) {
        setInEdit(taskId)
    }

    function enterAddition() {
        setInEdit("addition")
    }

    function cancelEdition() {
        setInEdit(null)
    }

    async function saveTask(id: TaskId, task: Task): Promise<void> {
        await axios.put(`/v1/cleaning/tasks/${id}`, task)
        modifyTask(id, (t) => task)
        cancelEdition()
    }

    async function addTask(task: Task): Promise<void> {
        const response = await axios.post("/v1/cleaning/tasks", task)
        const id: TaskId = response.data
        setTasks([...tasks, { id, task }])
        cancelEdition()
    }

    async function deleteTask(id: TaskId): Promise<void> {
        await axios.delete(`/v1/cleaning/tasks/${id}`)
        setTasks(tasks.filter(taskWithId => taskWithId.id !== id))
        cancelEdition()
    }

    async function onReservation(taskId: TaskId) {
        try {
            if (!activeUser) {
                throw new Error("No active user")
            }

            const now = moment.default()

            await axios.put(`/v1/cleaning/tasks/${taskId}/reservation`, JSON.stringify(now.toISOString()))
            modifyTask(taskId, (task: Task) => task.withReservation({ timestamp: now, user: activeUser }))
        } catch (error) {
            console.error(error)
            setError(true)
        }
    }

    async function onReservationCancel(taskId: TaskId) {
        try {
            await axios.delete(`/v1/cleaning/tasks/${taskId}/reservation`)
            modifyTask(taskId, (task: Task) => task.withoutReservation())
        } catch (error) {
            console.error(error)
            setError(true)
        }
    }

    async function onReservationConfirmation(taskId: TaskId, task: Task) {
        if (task.reservation && task.reservation.timestamp) {
            const reservation = task.reservation
            try {
                await axios.post(`/v1/cleaning/tasks/${taskId}/reservation/confirmation`)
                modifyTask(taskId, (task: Task) => task.withoutReservation().withNewRecord(reservation.timestamp, reservation.user))
                EventBus.taskUpdated(reservation.timestamp)
            } catch (error) {
                console.error(error)
                setError(true)
            }
        } else {
            console.error(`No reservation for task ${taskId}`)
            setError(true)
        }
    }

    useEffect(() => {
        getList()
    }, [getList])

    if (error) {
        return <Alert variant="danger">Oopsy, something went wrong</Alert>
    } else if (loading) {
        return <div className="text-center"><Spinner animation="border" className="mt-3" /></div>
    } else {
        if (activeUser) {
            const tasksReservedByActiveUser = tasks.filter(taskWithId => taskWithId.task.reservation?.user === activeUser)

            const suggestedTasks = tasks.filter(taskWithId => suggestions.includes(taskWithId.id) && taskWithId.task.reservation?.user !== activeUser)

            return <Stack gap={3}>
                {tasksReservedByActiveUser.length > 0 &&
                    <div>
                        <h5>Reserved by me</h5>
                        <ListGroup as="ul">
                            {tasksReservedByActiveUser.map(taskWithId => {
                                const { id, task } = taskWithId
                                return <ListGroup.Item key={id.toString()}>
                                    <TaskItem task={task} onDone={() => markAsDone(id)} onUndo={() => undo(taskWithId)} onEdit={async () => enterEdition(id)} onReservation={() => onReservation(id)} onReservationCancel={() => onReservationCancel(id)} onReservationConfirmation={() => onReservationConfirmation(id, task)} getUserName={userId => userMap[userId]} activeUser={activeUser}></TaskItem>
                                </ListGroup.Item>
                            })}
                        </ListGroup>
                    </div>
                }
                {suggestedTasks.length > 0 &&
                    <div>
                        <h5>Suggested</h5>
                        <ListGroup as="ul">
                            {suggestedTasks.map(taskWithId => {
                                const { id, task } = taskWithId
                                return <ListGroup.Item key={id.toString()}>
                                    <TaskItem task={task} onDone={() => markAsDone(id)} onUndo={() => undo(taskWithId)} onEdit={async () => enterEdition(id)} onReservation={() => onReservation(id)} onReservationCancel={() => onReservationCancel(id)} onReservationConfirmation={() => onReservationConfirmation(id, task)} getUserName={userId => userMap[userId]} activeUser={activeUser}></TaskItem>
                                </ListGroup.Item>
                            })}
                        </ListGroup>
                    </div>
                }
                <div>
                    <h5>All tasks</h5>
                    <ListGroup as="ul">
                        {tasks.filter(taskWithId => !suggestions.includes(taskWithId.id) && taskWithId.task.reservation?.user !== activeUser).map(taskWithId => {
                            const { id, task } = taskWithId

                            return <ListGroup.Item key={id.toString()}>
                                <div>
                                    {inEdit === id ?
                                        <TaskEditor task={task} onSave={(task) => saveTask(id, task)} onCancel={() => cancelEdition()} onDelete={() => deleteTask(id)} /> :
                                        <TaskItem task={task} onDone={() => markAsDone(id)} onUndo={() => undo(taskWithId)} onEdit={async () => enterEdition(id)} onReservation={() => onReservation(id)} onReservationCancel={() => onReservationCancel(id)} onReservationConfirmation={() => onReservationConfirmation(id, task)} getUserName={userId => userMap[userId]} activeUser={activeUser}></TaskItem>
                                    }
                                </div>
                            </ListGroup.Item>
                        })}
                        {inEdit === "addition" && <ListGroup.Item key="addition"><TaskEditor task={null} onSave={(task) => addTask(task)} onCancel={() => cancelEdition()} onDelete={null} /></ListGroup.Item>}
                    </ListGroup>
                </div>
                {!inEdit && <div className="text-center mt-3 mb-2"><Button variant="success" onClick={() => enterAddition()}><FontAwesomeIcon icon={faPlus} /> Add new task</Button></div>}
            </Stack>
        } else {
            return <Alert variant="warning">No active user</Alert>
        }
    }
}

interface TaskItemProperties {
    task: Task,
    onDone: () => Promise<void>,
    onUndo: () => Promise<void>,
    onEdit: () => Promise<void>,
    onReservation: () => Promise<void>,
    onReservationCancel: () => Promise<void>,
    onReservationConfirmation: () => Promise<void>,
    getUserName: (userId: string) => string
    activeUser: string
}

const TaskItem: React.FC<TaskItemProperties> = ({ task, onDone, onUndo, onEdit, onReservation, onReservationCancel, onReservationConfirmation, getUserName, activeUser }) => {
    let variant: string

    const lastDoneBy = task.mostRecentRecord()?.user.toString()

    if (task.timeRemainingPercent > 50) {
        variant = "success"
    } else if (task.timeRemainingPercent > 20) {
        variant = "warning"
    } else {
        variant = "danger"
    }

    const reservedBySomeoneElse: boolean = !!task.reservation && (task.reservation.user !== activeUser)

    return <div style={{ opacity: reservedBySomeoneElse ? 0.5 : 1.0 }}>
        <div className="d-flex justify-content-between align-items-center">
            <h5 className="my-0 flex-grow-1">
                {task.group ? task.group + " / " : ""}{task.name}
                <br />
                <small className="text-body" style={{ fontWeight: 400 }}>
                    {task.timeAgo()} / {task.period.humanize()}
                    {task.reservation ?
                        <div><FontAwesomeIcon icon={faCalendar} /> {getUserName(task.reservation.user.toString())} {task.reservation.timestamp.fromNow()}</div>
                        :
                        (lastDoneBy && <div><FontAwesomeIcon icon={faUserCheck} /> {getUserName(lastDoneBy)}</div>)
                    }
                </small>
            </h5>
            <TaskButtons
                isToday={task.isToday()}
                reserved={!!task.reservation}
                onDone={onDone}
                onUndo={onUndo}
                onEdit={onEdit}
                onReservation={onReservation}
                onReservationCancel={onReservationCancel}
                onReservationConfirmation={onReservationConfirmation}
                disableReservationConfirmation={reservedBySomeoneElse}
            />
        </div>
        <ProgressBar variant={variant} striped now={Math.abs(task.timeRemainingPercent)} label={`${task.timeRemainingPercent}%`} className={classNames({ "mt-1": true, "flex-row-reverse": task.timeRemainingPercent < 0 })}></ProgressBar>
    </div>
}


export default TaskList