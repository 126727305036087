import { faSignOut } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAuth } from "oidc-react"
import { useState } from "react"
import { Button, Container, Nav, Navbar } from "react-bootstrap"
import { Outlet, useLocation, useNavigate } from "react-router"

export const Wrapper = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const auth = useAuth()
    const [expanded, setExpanded] = useState(false)

    const go = (path: string) => {
        navigate(path)
        setExpanded(false)
    }

    const signOut = async () => {
        await auth.signOutRedirect({post_logout_redirect_uri: window.location.origin + "/signed-out"})
    }

    return <div className="d-flex flex-column h-100">
        <Navbar bg="dark" variant="dark" className="mb-3" expand="md" collapseOnSelect expanded={expanded}>
            <Container fluid>
                <Navbar.Brand href="#">Wojnowski.me</Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded => !expanded)} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <Nav.Item><Nav.Link active={location.pathname.startsWith("/cleanapp")} onClick={() => go("/cleanapp")}>🧹 Sprzątappka</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link active={location.pathname.startsWith("/happit")} onClick={() => go("/happit")}>💪 Happit</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link active={location.pathname.startsWith("/sugapp")} onClick={() => go("/sugapp")}>🍬 Cukrappka</Nav.Link></Nav.Item>
                    </Nav>
                    <Button variant="outline-secondary" className="ms-auto" onClick={signOut}><FontAwesomeIcon icon={faSignOut} /> Sign out</Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <div className="overflow-auto">
            <Outlet />
        </div>
    </div>
}
