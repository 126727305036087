import moment from "moment"
import { Col, Container, Row } from "react-bootstrap"
import { Outlet } from "react-router"
import { HabitRepository } from "./HabitRepository"
import { HabitRepositoryContext } from "./HabitRepositoryContext"
import { useAxios } from "../AxiosContext"

const HabitsApp = () => {
    const axios = useAxios()

    const habitRepository = new HabitRepository(axios, moment.duration(5, "seconds"))

    return (
        <Container>
            <Row>
                <Col>
                    <HabitRepositoryContext.Provider value={habitRepository}>
                        <Outlet/>
                    </HabitRepositoryContext.Provider>
                </Col>
            </Row>
        </Container>
    )
}

export default HabitsApp