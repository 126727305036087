import { Moment } from "moment"

export interface Habit {
    name: string
    expectations: HabitExpectations
    position: number
    sensitive: boolean
}

export type HabitId = string

export interface HabitWithDetails {
    id: HabitId
    habit: Habit,
    periodRecords: HabitRecord[]
}

export interface HabitExpectations {
    minimum: number
    optimum: number
    period: HabitExpectationsPeriod
}

export enum HabitExpectationsPeriod {
    Day = "day",
    Week = "week"
}

export interface HabitRecord {
    instant: Moment,
    diff: number
}