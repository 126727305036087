import { WebStorageStateStore } from "oidc-client-ts"
import { AuthProvider, UserManager } from "oidc-react";
import { AxiosContext, createAxiosContext } from "./AxiosContext";
import { useEffect, useMemo, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

interface Props {
    children?: React.ReactNode
}

export function AsyncAuthProvider({ children }: Props) {
    const [loading, setLoading] = useState(true)
    const [signedIn, setSignedIn] = useState(false)



    const userManager = useMemo(() => {
        const audience = process.env.REACT_APP_BACKEND_URL || ""

        return new UserManager({
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: process.env.REACT_APP_OIDC_PROVIDER_URL || "",
            client_id: process.env.REACT_APP_OIDC_CLIENT_ID || "",
            redirect_uri: window.location.origin + "/signed-in",
            post_logout_redirect_uri: window.location.origin + "/signed-out",
            scope: "offline_access",
            automaticSilentRenew: false,
            extraQueryParams: { audience },
            extraTokenParams: { audience }
        })
    }, [])

    useEffect(() => {
        const fetchUser = async () => {
            try {
                setLoading(true)
                const user = await userManager.getUser()
                setSignedIn(!!user)
            } catch (e) {
                console.error(e)
            } finally {
                setLoading(false)
            }
        }

        const onSignedIn = () => {
            console.log("Event signed in")
            setSignedIn(true)
        }

        const onSignedOut = () => {
            console.log("Event signed out")
            setSignedIn(false)
        }

        userManager.events.addUserLoaded(onSignedIn)
        userManager.events.addUserSignedOut(onSignedOut)
        userManager.events.addUserUnloaded(onSignedOut)

        fetchUser()

        return () => {
            userManager.events.removeUserLoaded(onSignedIn)
            userManager.events.removeUserSignedOut(onSignedOut)
            userManager.events.removeUserUnloaded(onSignedOut)
        }

    }, [userManager])

    return (
        <AuthProvider userManager={userManager} autoSignIn={false} autoSignOut={false}>
            {(signedIn && !loading) ?
                <AxiosContext.Provider value={createAxiosContext(userManager)}>
                    {children}
                </AxiosContext.Provider> :
                <div className="text-center pt-3">
                    {loading ? <><Spinner animation="border" /></> :
                        <Button onClick={() => { setLoading(true); userManager.signinRedirect() }}><FontAwesomeIcon icon={faLock} /> Sign in</Button>
                    }
                </div>
            }
        </AuthProvider >
    )
}
