import { Axios } from "axios";
import moment, { Duration, Moment } from "moment";
import { Habit, HabitId, HabitWithDetails } from "./Habit";

export class HabitRepository {
    private updateCache: Map<HabitId, [Moment, Habit]> = new Map()
    private creationCache: [Moment, HabitWithDetails][] = []
    private deletionCache: Map<HabitId, Moment> = new Map()

    private expirationTime: Duration
    private axios: Axios

    constructor(axios: Axios, expirationTime: Duration) {
        this.axios = axios
        this.expirationTime = expirationTime
    }

    public async createHabit(habit: Habit) {
        try {
            const response = await this.axios.post("/v1/habits", habit)
            const now = moment()
            const id: HabitId = response.data
            this.creationCache.push([now, { id, habit, periodRecords: [] }])
        } catch (error) {
            console.log(error)
        }
    }

    public async updateHabit(habitId: HabitId, habit: Habit) {
        try {
            const response = await this.axios.put(`/v1/habits/${habitId}`, habit)
            const now = moment()
            this.updateCache.set(habitId, [now, habit])
        } catch (error) {
            console.log(error)
        }
    }

    public async listHabits(zoneId: string, additionalOffsetHours: number): Promise<HabitWithDetails[]> {
        try {
            this.expire()
            const response = await this.axios.get("/v1/habits", { params: { zoneId, additionalOffsetHours } })
            const habitsWithDetails: HabitWithDetails[] = response.data
            const upToDateHabitsWithDetails: HabitWithDetails[] = habitsWithDetails.map(habitWithDetails => {
                const maybeUpdatedHabit = this.updateCache.get(habitWithDetails.id)
                if (maybeUpdatedHabit) {
                    return { ...habitWithDetails, habit: maybeUpdatedHabit[1] }
                } else {
                    return habitWithDetails
                }
            }).filter(habitWithDetails => !this.deletionCache.has(habitWithDetails.id))

            const newNotListedHabits =
                this
                    .creationCache
                    .filter(([, habitWithDetails]) => !upToDateHabitsWithDetails.find(other => other.id === habitWithDetails.id))
                    .map(([, habitWithDetails]) => habitWithDetails)

            return upToDateHabitsWithDetails.concat(newNotListedHabits).sort((a, b) => a.habit.position - b.habit.position)
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    public async deleteHabit(habitId: HabitId) {
        try {
            await this.axios.delete(`/v1/habits/${habitId}`)
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    public async getHabit(habitId: HabitId): Promise<Habit | undefined> {
        try {
            this.expire()
            const response = await this.axios.get(`/v1/habits/${habitId}`)
            return response.data as Habit
        } catch (error) {
            console.log(error)
        }
    }

    private expire() {
        const now = moment()

        const isExpired = (moment: Moment) => moment.add(this.expirationTime).isBefore(now)

        this.updateCache.forEach(([moment,], id) => {
            if (isExpired(moment)) {
                this.updateCache.delete(id)
            }
        })

        this.creationCache = this.creationCache.filter(([moment,]) => !isExpired(moment))

        this.deletionCache.forEach((moment, id) => {
            if (isExpired(moment)) {
                this.deletionCache.delete(id)
            }
        })
    }
}